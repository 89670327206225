export const getTime = () => {
  let hours = new Date().getHours();
  let minutes = new Date().getMinutes();
  let seconds = new Date().getSeconds() ;
  minutes = minutes < 10 ? '0'+minutes : minutes;
  let strTime = `${hours}:${minutes}:${seconds}`;
  return strTime;
}

export const duration = (start,end) => {
  start = start.split(":");
  end = end.split(":");
  let startDate = new Date(0, 0, 0, start[0], start[1], start[2]);
  let endDate = new Date(0, 0, 0, end[0], end[1], end[2]);
  let diff = endDate.getTime() - startDate.getTime();
  let hours = Math.floor(diff / 1000 / 60 / 60);
  diff -= hours * 1000 * 60 * 60;
  let minutes = Math.floor(diff / 1000 / 60);
  diff -= minutes * (1000 * 60);
  let seconds = Math.floor(diff / 1000);
  return `${(hours < 9 ? "0" : "")}${hours}:${(minutes < 9 ? "0" : "")}${minutes}:${(seconds<= 9 ? "0" : "")}${seconds}`;
}

export let startTime = getTime();

export const startSession = () => {
  return `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()} ${getTime()}`;
}