import React, { Component } from "react";
import PropTypes from "prop-types";

import { FaAngleDown, FaAngleUp } from "react-icons/fa";

class AccordionSection extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const { onClick, props: { isOpen, label } } = this;

    return (
      <div className={`acc-item ${isOpen && `open`}`}>
        <div onClick={onClick} className="acc-head">
          {label} 
          { isOpen ?
            <FaAngleUp className="acc-btn" />
            : <FaAngleDown className="acc-btn"/>
          }  
        </div>
        <div className={`acc-content`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default AccordionSection;
