import React from 'react';
import styled from 'styled-components';
import { MixpanelConsumer } from 'react-mixpanel';


const dummyImage = require('src/assets/images/placeholder.png')
const ListItem = (props) => {
  const { customTheme, handleClickTrack, rootUrl, handle } = props;
  return (
    <MixpanelConsumer key={props._id}>
      {
        mixpanel => (
          <Item className="list-item">
            {/* onClick={(e) => props.handleClickTrack(mixpanel, props.brand, props.productName, props.handle, e)} */}
            <a 
              href={`${rootUrl}${rootUrl[rootUrl.length-1]!=='/' ? '/': ''}${handle}`} 
              target='_blank' rel="noopener noreferrer" 
              title={props.productName}
              onClick={(e) => props.handleClickTrack(mixpanel, props.brand, props.productName, props.handle, e)}
            >
              <Image imageUrl={props.imageURL} />
              <div className="list-content">
                <Brand className="brand">
                  {props.brand}
                </Brand>
                <Name>{props.productName}</Name>
                <Price className="price">
                  {props.price ? `$${Math.round(props.price)}` : null }
                </Price>
              </div>
            </a>
          </Item>
        )
      }
    </MixpanelConsumer>
  )
}

const Item = styled.li`
  width: 33.33%;
  margin: 10px 0;
  padding: 20px 0 30px;
  list-style: none;
  position: relative;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${(props) => props.theme.primaryColor};
  }
  
  @media screen and (max-width: 768px) { 
    padding-top: 0;
    padding: 20px 0 180px;
    width: 50%;
  }
`

const Brand = styled.span`
  margin: 5px 0;
  display: block;
  color: #231f20;
  text-transform: uppercase;
  font-family: primary-selected-font, Crimson Text, sans-serif;
  font-size: 15px;
`
  // font-family: 'Crimson Text',sans-serif;
const Title = styled.span`
  margin: 10px 0;
  display: block;
  font-size: 12px;
  color: ${(props) => props.theme.primaryText };
  text-transform: uppercase;
`
const Price = styled.span`
  margin: 10px 0 0;
  display: block;
  font-family: secondary-selected-font, "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: .8px;
  color: #231f20;
  text-transform: uppercase;
`
const Image = styled.div`
  height: 300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => (props.imageUrl === 'NOURL' || !props.imageUrl ? dummyImage : props.imageUrl)});  

  @media screen and (max-width: 768px) { 
    height: 250px; 
  }   
`
const Name = styled.span`
  color: #231f20;
  font-family: secondary-selected-font, "Roboto", sans-serif;
  font-size: 18px;
  @media screen and (max-width: 768px) { 
    word-break: break-word;
    font-size: 16px; 
  }   
`

export default ListItem ;