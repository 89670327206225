import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';
import { PersistGate } from 'redux-persist/integration/react';

import 'assets/style/reset.scss';
import 'src/global';
import { ThemeProvider } from 'styled-components';

import { Provider } from 'react-redux';
import Routes from './routes';

import configureStore from './store';
import * as serviceWorker from './serviceWorker';
import { startSession, startTime, duration, getTime } from 'utils/SessionUtils';
import { components } from 'react-select/lib/components';

const clientBasedTheme = (() => {
  const theme = {
    credo: {
      clearTheme: '#6fd7dc',
      primaryText: '#656C83',
      headingText: '#777',
      primaryColor: '#b75b6b',
      primaryFaded: '#b75b6b7a',
      backgroundColor: '#fff'
    },
    cfm: {
      clearTheme: '#6fd7dc',
      primaryText: '#656C83',
      headingText: '#777',
      primaryColor: '#6fd7dc',
      primaryFaded: '#6fd7dc7a',
      backgroundColor: '#fff'
    }
  };
  let isInIframe = window !== window.parent;
  let currentUrl = null;
  if (isInIframe) {
    currentUrl = document.referrer;
  } else {
    currentUrl = window.location.href;
  }

  if (
    currentUrl.search('https://credobeauty.com/') > -1 ||
    currentUrl.search('https://alibirouti.com/') > -1
  ) {
    return theme.credo;
  } else {
    return theme.cfm;
  }
})();

const searchToolIdentifier = (() => {
  const searchTool = {
    credo: {
      url: 'https://credobeauty.com/',
      clientName: 'CredoBeauty'
    },
    cfm: {
      url:
        'http://client-interface-staging.s3-website-us-west-2.amazonaws.com/',
      clientName: 'ClearForMe'
    }
  };
  let isInIframe = window !== window.parent;
  let currentUrl = null;
  if (isInIframe) {
    currentUrl = document.referrer;
  } else {
    currentUrl = window.location.href;
  }

  if (currentUrl.includes(searchTool.credo.url)) {
    return searchTool.credo.clientName;
  } else {
    return searchTool.cfm.clientName;
  }
})();

const { store, persistor } = configureStore();

mixpanel.init(`${global.MIXPANEL_TOKEN}`);
mixpanel.track('Session Start time', { value: startSession() });
mixpanel.track('SearchUI tool used', { value: searchToolIdentifier });

window.onbeforeunload = function() {
  let endTime = getTime();
  mixpanel.track('Session duration', { value: duration(startTime, endTime) });
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={clientBasedTheme}>
        <MixpanelProvider mixpanel={mixpanel}>
          <Routes theme={clientBasedTheme} />
        </MixpanelProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
