import React from 'react';
import { Link } from 'react-router-dom';

// styles
import './style/style.scss';

const Header = () => {
  return(
    <header>
      <div className="wrapper">
        <h1 className="logo">
          <Link to="/" title="Clear For Me">
            clear<span>For</span>Me
          </Link>  
        </h1>
      </div>
    </header>
  )
}

export default Header;