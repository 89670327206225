import React, { Component, Fragment } from "react";
import styled from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';
import renderMarkup from 'utils/renderMarkup';
import "./style/style.scss";

const DefaultInfoTitle = (props) => {
  return (
    <StyledDefaultTitle>
      <strong>Important:</strong> Please read this note if you are allergic/sensitive
    </StyledDefaultTitle>
  )
}

const DefaultInfoBody = () => {
  return <Fragment>
    <span>
      Our filter is able to narrow in on what you’re looking for, but it cannot (yet) get down to the <strong>source</strong> of many ingredients. For example, if you exclude “coconut oil,” then products with the oil itself will be filtered out. But, other ingredients like caprylhydroxamic acid, a preservative that may be derived from coconuts, would not be filtered out. If you are sensitive/allergic to something, we encourage you to read ingredient lists carefully, contact brands directly for source info, and/or consult with a doctor. We’re continually iterating this search tool, so functionality will get better! But source-level data is tricky stuff. If you have any feedback or questions, email us at <Mail href="mailto:arealperson@credobeauty.com">arealperson@credobeauty.com</Mail> or chat with us below!
    </span>
  </Fragment>
}

class InfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false
    }
  }

  openModal = () => {
    this.setState(
      { openModal: true },
      () => {
        this.addBodyOverflow();
      }
      );
  }
  
  closeModal = () => {
    this.setState(
      { openModal: false },
      () => {
        this.removeBodyOverflow();
      }
    );
  }


  addBodyOverflow = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    body.style.overflow = "hidden";
    if(/iP(hone|od|ad)/.test(navigator.platform)) {
      body.classList.add('iosBody');
      html.classList.add('iosBody');
    }
  }

  removeBodyOverflow = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');

    body.style.overflow = "auto";
    if(/iP(hone|od|ad)/.test(navigator.platform)) {
      body.classList.remove('iosBody');
      html.classList.remove('iosBody');
    }
  }

  render() {
    const { title, body, customTheme } = this.props;
    const { openModal } = this.state;
    const iconColor = this.props.customTheme && this.props.customTheme.background  && this.props.customTheme.background.colorTheme.primary.informationIconForDisclaimer.css.textColor;

    return (
      <Fragment>
        <Info>
          <span onClick={this.openModal}>
            <StyledIcon customcolor={iconColor ? iconColor : null}/> { title ? title : <DefaultInfoTitle /> }
          </span>
        </Info>
          <StyleModal className="modal_container" openModal={openModal}>
            <StyledBackdrop openModal={openModal} onClick={this.closeModal}></StyledBackdrop>
            <div className="modal">
              {
                customTheme && customTheme.disclaimerContents ? 
                <StyledDisclaimer 
                  customcolor={iconColor ? iconColor : null}
                  dangerouslySetInnerHTML={renderMarkup(customTheme.disclaimerContents.html ? customTheme.disclaimerContents.html : `<span>Our filter is able to narrow in on what you’re looking for, but it cannot (yet) get down to the <strong>source</strong> of many ingredients. For example, if you exclude “coconut oil,” then products with the oil itself will be filtered out. But, other ingredients like caprylhydroxamic acid, a preservative that may be derived from coconuts, would not be filtered out. If you are sensitive/allergic to something, we encourage you to read ingredient lists carefully, contact brands directly for source info, and/or consult with a doctor. We’re continually iterating this search tool, so functionality will get better! But source-level data is tricky stuff. If you have any feedback or questions, email us at <a href="mailto:arealperson@credobeauty.com">arealperson@credobeauty.com</a> or chat with us below!</span>`)} 
                  size={customTheme.disclaimerContents.css && customTheme.disclaimerContents.css.fontSize ? customTheme.disclaimerContents.css.fontSize : null}  
                /> :
                <DefaultInfoBody />
              }
                <span className="close" onClick={this.closeModal}>&times;</span>
            </div>
          </StyleModal>
      </Fragment>
    )
  }
}

export default InfoModal;

const Info = styled.div`
  font-size: 13px;
  line-height: 1;
  font-family: secondary-selected-font, "Roboto", sans-serif;
  letter-spacing: .5px;
  color: #000;
  span { cursor: pointer; }
`
const StyleModal = styled.div`
  display: ${(props) => props.openModal ? "block" : "none"}
`

const StyledBackdrop = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => props.openModal ? "block" : "none"}
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.5);
`
const Mail = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.primaryColor}
  cursor: pointer;
`
const StyledIcon = styled(FaInfoCircle)`
  color: ${(props) => props.customcolor ? props.customcolor : props.theme.primaryColor};
`

const StyledDefaultTitle = styled.span`
  color: #231f20;
` 

const StyledDisclaimer = styled.div`
  font-size: ${props => props.size ? props.size : '16px'}
  a {
    display: inline-block;
    color: ${(props) => props.customcolor ? props.customcolor : props.theme.primaryColor}
    text-decoration: underline;
    cursor: pointer;
  }
`