import axios from 'axios';

/**
 * Fetch default
 */

class FetchUtils {
  
  postData = async(url, body, log) => {
    try {
      let config = {
        headers: {'Authorization': `Bearer ${global.TOKEN}` }
      };
      const response = await axios.post(url, body, config);
			return response;
		}
		catch(err) {
			console.log("error ^^^^^^^",err)
			return {
        data: err.response.data,
        status: err.response.status,
        headers: err.response.headers,
      };
		}
	} 

	getData = async (url,log) => {
    try {
      let config = {
        headers: {'Authorization': `Bearer ${global.TOKEN}` }
      };
      const response = await axios.get(url,config);
			// console.log(log,response);            
			return response;
		}
		catch(err) {
			console.log("error ^^^^^^^",err)
			return err;
		}
	}

}

export default new FetchUtils();

