import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

/* Import components here */
import SearchPage from 'pages/search';
import ListingPage from 'pages/listing';
import ErrorPage from 'pages/error';

const mapStateToProps = state => ({
	...state
});

/* Include routes here*/
function Routes(theme) {
	return (
		<Router  onUpdate={() => window.scrollTo(0, 0)}>
			<Switch>
				<Route exact path='/' render={(props) => <SearchPage {...props} {...theme} />} />
				<Route exact path='/listing' render={(props) => <ListingPage {...props} {...theme}/> } />
				<Route exact path='/error' render={(props) => <ErrorPage {...props} {...theme}/> } />
			</Switch>
		</Router>
	)
}

export default connect(mapStateToProps)(Routes);