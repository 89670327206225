import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '../../assets/images/spinner.gif';

// styles
import './style/style.scss';

const Button = (props) => {
    let {
      buttonType,
      buttonText,
      handleClick,
      disabled,
      isLoading,
      resultCount,
      loader,
      flag,
      customTheme,
    } = props;

    return(
      <div className={`${buttonType} ${disabled && `disabled`} submit_button`}>
        <div className="empty"></div>
        <div className="button">
          <ThemedButton 
            to="/"
            title={buttonText}
            onClick={handleClick}
            disabled={disabled}
            custombackgroundcolor={customTheme && customTheme.background ? customTheme.background.colorTheme.secondary.findButton.css.backgroundColor ? customTheme.background.colorTheme.secondary.findButton.css.backgroundColor : '#418993' : '#418993'}
            customtextcolor={customTheme  && customTheme.background  ? customTheme.background.colorTheme.secondary.findButton.css.textColor ? customTheme.background.colorTheme.secondary.findButton.css.textColor : '#fff' : '#fff'}
          >
            {buttonText}
          </ThemedButton>
          {
            isLoading ?  <Loader>
                <img src={Spinner}/>
              </Loader>:
            <ResultCount flag={flag}>{`${resultCount} Matches Found`}</ResultCount>
          }
        </div>
      </div>
    )
}

const Loader = styled.div`
  position: relative;
  display: inline-block;
  width: 50px;
  height: auto;
  vertical-align: middle;
  margin-left: 25px;
`

const ThemedButton = styled(Link)`
  width: 211px;
  padding: 16px 0;
  display: inline-block;
  border: ${props => `2px solid ${props.custombackgroundcolor}`};
  color: ${props => props.customtextcolor}
  background-color: ${props => props.custombackgroundcolor}
  font-family: secondary-selected-font,"Roboto",sans-serif;
  font-size: 24px;
  font-weight: 900;
  
  &:hover {
    color: ${(props) => props.theme.primaryText};
    background-color: #fff;
  }
  `
  // color: #fff;
  // background-color: #418993;
const ResultCount = styled.p`
  color: #000;
  font-family: secondary-selected-font,"Roboto",sans-serif;
  display: ${(props) => props.flag ? "inline-block" : "none"};
`;

export default Button;