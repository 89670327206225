import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Button, Loader, Form } from 'components';
import styled from 'styled-components';

import InfoModal from 'components/infoModal';
import { SelectFilterAction } from 'actions/SelectFilterAction';
import { clientNameAction } from 'actions/clientNameAction';
import { getFilteredList } from 'src/services/SearchServices';
import fetchUtils from 'utils/FetchUtils';
import renderMarkup from 'utils/renderMarkup';
import defaultBackgroundImage from 'assets/images/header-image.jpg';
import './style/style.scss';

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = dispatch => ({
  SelectFilterAction: selectedFilter =>
    dispatch(SelectFilterAction(selectedFilter)),
  clientNameAction: data => dispatch(clientNameAction(data))
});

/*
 * mapStateToProps
 */
const mapStateToProps = state => ({
  ...state
});

/**
 * @class SearchPage
 * @extends {Component}
 */

/**
 * Extracting labels for tracking
 */
export const trackValues = data => {
  let toTrack = {};
  Object.keys(data).map(key => {
    data[key].map(item => {
      toTrack[key] ? (toTrack[key] = toTrack[key]) : (toTrack[key] = []);
      toTrack[key].push(item.label);
    });
  });
  return toTrack;
};

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: {},
      isLoading: false,
      searchStep: 1,
      resultCount: 0,
      flag: false
    };
  }

  componentDidMount() {
    this.getClientName();
  }

  getClientName = async () => {
    localStorage.clear();
    this.props.clientNameAction('');
    let body = {
      domain: window !== window.parent ? document.referrer : window.location.href
    }
    let response = await fetchUtils.postData(`${global.CMS_BASE_URL}/client`, body);
    if(response && response.status === 200) {
      this.props.clientNameAction(response.data);
      this.customThemeData(response.data.clientName);
    } else {
      this.customThemeData(false);
    }
  }

  customThemeData = async (clientName) => {
    let response = await fetchUtils.getData(
      `${global.CMS_BASE_URL}/stylesheet?clientName=clearforme`
    );
    return response && response.data && clientName
      ? this.checkForWhiteList(response.data, clientName)
      : this.setState({ customTheme: true });
  };

  loadFonts = async (url, fontLevel) => {
    let font = '';
    if(fontLevel === 'secondary') {
      font = new FontFace('secondary-selected-font', `url(${url})`);
    } else if (fontLevel === 'primary') {
      font = new FontFace('primary-selected-font', `url(${url})`);
    }
    await font.load();
    document.fonts.add(font);
  }

  checkForWhiteList = async (data, clientName) => {
    let baseUrl = '';
    if(window !== window.parent) {
      baseUrl = document.referrer;
    } else {
      baseUrl = window.location.href;
    }
    if (baseUrl) {
      let result = data.whiteList.find(item => baseUrl.search(item.domainName) > -1);
      if (result) {
        let response = await fetchUtils.getData(
          `${global.CMS_BASE_URL}/stylesheet?clientName=${clientName}`
        );
        if(response.status===200 && response.data) {
          //loading secondary font
          if(response.data.siteFont && response.data.siteFont.secondaryFont.fontFamily) {
            let fontData =  response.data.siteFont.secondaryFont.fontWeight
              ? response.data.siteFont.secondaryFont.fontFamily.files[response.data.siteFont.secondaryFont.fontWeight]
              : response.data.siteFont.secondaryFont.fontFamily.files[
                  Object.keys(response.data.siteFont.secondaryFont.fontFamily.files)[0]
                ]
            if(fontData) {
              this.loadFonts(fontData, 'secondary');
            }
          }
          
          //loading primary font
          if(response.data.siteFont && response.data.siteFont.primaryFont.fontFamily) {
            let fontData =  response.data.siteFont.primaryFont.fontWeight
              ? response.data.siteFont.primaryFont.fontFamily.files[response.data.siteFont.primaryFont.fontWeight]
              : response.data.siteFont.primaryFont.fontFamily.files[
                  Object.keys(response.data.siteFont.primaryFont.fontFamily.files)[0]
                ]
            if(fontData) {
              this.loadFonts(fontData, 'primary');
            }
          }

          //setting tertiary color
          document.querySelector('body').style.color =
          response.data.background && response.data.background.colorTheme
            ? response.data.background.colorTheme.tertiary.css.textColor
              ? response.data.background.colorTheme.tertiary.css.textColor
              : 'inherit'
            : 'inherit';
          this.setState({ customTheme: response.data });
        } else {
          this.setState({ customTheme: true });
        }
      } else {
        this.setState({ customTheme: true });
      }
    }
  };

  nextStep = nextStep => {
    let { searchStep } = this.state;
    if (nextStep <= 3 && !(nextStep <= searchStep)) {
      this.setState({
        searchStep: searchStep + 1
      });
    }
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  initialSearchTracking = (mixpanel, data, title) => {
    let toTrack = [];
    data.map(item => {
      toTrack.push(item.label);
    });
    mixpanel.track(`Initial Search: ${title}`, { value: toTrack });
  };

  /*
   * Handle Change of input
   */
  handleChange = (value, mixpanel) => (selectedOption, { action }) => {
    if (value === 'categories') {
      let selectedValue = this.state.selectedOption;
      selectedValue[value] = selectedOption;
      if (
        action === 'select-option' ||
        action === 'remove-value' ||
        action === 'clear'
      ) {
        this.setState(
          {
            selectedOption: selectedValue,
            isLoading: true
          },
          () => {
            this.getListing(selectedOption);
          }
        );
        if (action === 'select-option') {
          this.nextStep(2);
        }
        this.initialSearchTracking(mixpanel, selectedOption, 'category');
      }
    } else if (value === 'includeIngredients') {
      let selectedValue = this.state.selectedOption;
      selectedValue[value] = selectedOption;
      if (
        action === 'select-option' ||
        action === 'remove-value' ||
        action === 'clear'
      ) {
        this.setState(
          {
            selectedOption: selectedValue,
            isLoading: true
          },
          () => {
            this.getListing(selectedOption);
          }
        );
        if (action === 'select-option') {
          this.nextStep(3);
        }
        this.initialSearchTracking(
          mixpanel,
          selectedOption,
          'Include Ingredients'
        );
      }
    } else if (value === 'excludeIngredients') {
      let selectedValue = this.state.selectedOption;
      selectedValue[value] = selectedOption;
      if (
        action === 'select-option' ||
        action === 'remove-value' ||
        action === 'clear'
      ) {
        this.setState(
          {
            selectedOption: selectedValue,
            isLoading: true
          },
          () => {
            this.getListing(selectedOption);
          }
        );
        if (action === 'select-option') {
          this.nextStep(4);
        }
        this.initialSearchTracking(
          mixpanel,
          selectedOption,
          'Exclude Ingredients'
        );
      }
    } else {
      console.log('Invalid Field');
    }
  };

  getListing = async selectedOption => {
    const response = await getFilteredList(this.state.selectedOption, this.props.clientName.clientName);
    if (response.status === 200 && typeof response.data.products === 'undefined') {
      this.setState({ resultCount: 0, isLoading: false });
    } else if (response.status === 200) {
      this.setState({
        resultCount: response.data.products.length,
        isLoading: false
      });
    } else {
      this.setState({ resultCount: 0, isLoading: false });
    }

    this.setState({ flag: true });
  };

  handleSubmit = async (e, mixpanel) => {
    let { selectedOption } = this.state;
    let track = trackValues(selectedOption);
    e.preventDefault();
    let isDisabled = selectedOption['categories']
      ? this.isEmpty(this.state.selectedOption['categories'])
      : true;
    if (isDisabled) {
      this.setState({ isDisabled: true });
    } else {
      this.setState({ isLoading: true, isDisabled: false });
      this.props.SelectFilterAction(this.state.selectedOption);
      mixpanel.track('Search', { value: track });
      this.props.history.push({
        pathname: `/listing`,
        state: this.state.customTheme
      });
    }
  };

  openModal = () => {
    this.setState({ openModal: true }, () => {
      this.addBodyOverflow();
    });
  };

  closeModal = () => {
    this.setState({ openModal: false }, () => {
      this.removeBodyOverflow();
    });
  };

  addBodyOverflow = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    body.style.overflow = 'hidden';
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      body.classList.add('iosBody');
      html.classList.add('iosBody');
    }
  };

  removeBodyOverflow = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');

    body.style.overflow = 'auto';
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      body.classList.remove('iosBody');
      html.classList.remove('iosBody');
    }
  };

  render() {
    let {
      isLoading,
      selectedOption,
      searchStep,
      isDisabled,
      resultCount,
      flag,
      customTheme
    } = this.state;
    let { theme } = this.props;
    return (
      <Fragment>
        { !customTheme && <Loader /> }
        {customTheme && (
          <SearchBackground 
            background={
              customTheme.background && customTheme.background.search && customTheme.background.search.css? customTheme.background.search.css.backgroundImage ? 
              {
                image: customTheme.background.search.css.backgroundImage.secure_url
              } 
              : customTheme.background.search.css.backgroundColor ? {
                color: customTheme.background.search.css.backgroundColor
              }
              : null
              : null
            } 
            className='search'
          >
            <div className='wrapper'>
              <div className='banner-content'>
                {/* <span className="intro">INTRODUCING</span> */}
                <div className='banner-text'>
                  <StyledTitle
                    size={
                      customTheme.searchTitle && customTheme.searchTitle.css
                        ? customTheme.searchTitle.css.fontSize
                        : null
                    }
                  >
                    <span>
                      {customTheme.searchTitle && customTheme.searchTitle.html
                        ? customTheme.searchTitle.html.split(' ')[0]
                        : 'Your'}
                    </span>{' '}
                    {customTheme.searchTitle && customTheme.searchTitle.html
                      ? customTheme.searchTitle.html
                          .split(' ')
                          .splice(1, customTheme.searchTitle.html.length)
                          .join(' ')
                      : 'Product Finder'}
                  </StyledTitle>
                  {customTheme.subTitle ? (
                    <StyledSubtitle
                      size={
                        customTheme.subTitle.css
                          ? customTheme.subTitle.css.fontSize
                          : null
                      }
                      dangerouslySetInnerHTML={renderMarkup(
                        customTheme.subTitle.html
                          ? customTheme.subTitle.html
                          : `<p>Search for products with ingredients you love. Have a sensitivity? Exclude ingredients to find the best products for you. <span>Don't worry, we never allow hundreds of ingredients found on our <a target="_blank" href="https://credobeauty.com/pages/the-credo-clean-standard#dirty">Dirty Ingredients List</a>.</span></p>`
                      )}
                    />
                  ) : (
                    <p>
                      Search for products with ingredients you love. Have a
                      sensitivity? Exclude ingredients to find the best products
                      for you.{' '}
                      <span>
                        Don't worry, we never allow hundreds of ingredients
                        found on our{' '}
                        <a
                          target='_blank'
                          href='https://credobeauty.com/pages/the-credo-clean-standard#dirty'
                        >
                          Dirty Ingredients List
                        </a>
                        .
                      </span>
                    </p>
                  )}
                </div>
                <Form
                  clearValue={this.clearValue}
                  selectedOption={selectedOption}
                  handleChange={this.handleChange}
                  searchStep={searchStep}
                  theme={theme}
                  handleSubmit={this.handleSubmit}
                  resultCount={resultCount}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  loader={<Loader />}
                  flag={flag}
                  customTheme={customTheme ? customTheme : null}
                  {...this.props}
                />
                {isDisabled && <Error>Please select a category.</Error>}
                <div className='modal-block'>
                  <div className='empty' />
                  <div className='modal-container'>
                    <InfoModal customTheme={customTheme ? customTheme : null} />
                  </div>
                </div>
              </div>
            </div>
          </SearchBackground>
        )}
      </Fragment>
    );
  }
}

const Error = styled.p`
  margin: 10px 0;
  color: red;
`;

const StyledTitle = styled.h2`
  font-size: ${props => (props.size ? props.size : '67px')};
  font-family: primary-selected-font, Crimson Text, sans-serif;
  span {
    font-family: inherit;
  }
`;

const StyledSubtitle = styled.div`
  font-family: secondary-selected-font, Roboto, sans-serif;
  font-size: ${props => (props.size ? props.size : '20px')};
  sup {
    vertical-align: super;
    font-size: small;
  }
`;

const SearchBackground = styled.div`
  height: 100%;
  position: relative;
  background: ${
    props => props.background ? props.background.image ? 
    `url(${props.background.image}) no-repeat` 
    : props.background.color ? props.background.color 
    : `url(${defaultBackgroundImage}) no-repeat` 
    : `url(${defaultBackgroundImage}) no-repeat`
  };
  background-size: 100% 100%;
`

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPage);
