import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


export default function configureStore() {
  let store =  createStore(
   persistedReducer,
    applyMiddleware(thunk)
  );
  let persistor = persistStore(store);
  return { store, persistor }
 };