import React, { Component, Fragment } from 'react';
import { MixpanelConsumer } from 'react-mixpanel';

import { AsyncDropdown } from 'components';
import { getCategory, getIngredients } from 'src/services/SearchServices';

import TextUtils from 'utils/TextUtils';
import Button from 'src/components/button';

import './style/style.scss';

const categoryDefaultOptions = true

const includeDefaultOptions = true;

const excludeDefaultOptions = true;

/**
 * @class Form
 * @extends {Component}
 */

class Form extends Component {

  /* 
  * Get category options
  */
  getCategoryOptions = async (inputValue) => {
    const response = await getCategory(inputValue, this.props.clientName.clientName);

    // format the option in react-select format({value: string , label: string})
    let options = response.data.map((ele) => {
      let eachOption = {};
      eachOption['label'] = TextUtils.capitalize(ele.category);
      eachOption['value'] = ele.category;
      return eachOption
    })

    // remove empty options
    let trimOptions = options.filter((ele) => !(!ele.value))

    return trimOptions
  }
  

  loadCategory = (inputValue) => {
    let options = this.getCategoryOptions(inputValue);
    return options
  }

  /* 
  * Get Ingredients options
  */
  getIngredientsOptions = async (inputValue,tag) => {
    const response = await getIngredients(inputValue,this.props.clientName.clientName,tag);

    // format the option in react-select format({value: string , label: string})
    let options = response.data.map((ele) => {
      let eachOption = {};
      if (ele.ingredientName) {
        eachOption['label'] = TextUtils.capitalize(ele.ingredientName);
        eachOption['value'] = ele.ingredientName;
        return eachOption;
      } else if (ele.synonym) {
        eachOption['label'] = TextUtils.capitalize(ele.synonym);
        eachOption['value'] = ele.synonym;
        return eachOption;
      }
    })
    return options
  }

  loadIngredients = tag => inputValue => {
    let options = this.getIngredientsOptions(inputValue,tag);
    return options
  }

  render() {
    let {
      handleChange,
      selectedOption,
      isPara,
      searchStep,
      theme,
      handleSubmit,
      isDisabled,
      changeCount,
      resultCount,
      isLoading,
      loader,
      flag,
      customTheme,
    } = this.props;
    return (
      <div className="form-container">
      <MixpanelConsumer>
        {
          mixpanel => (
            <Fragment>
              <AsyncDropdown
                defaultOptions={categoryDefaultOptions}
                value={selectedOption['categories']}
                isMulti={true}
                isPara={isPara}
                placeholder="Select a Category"
                handleChange={handleChange('categories', mixpanel)}
                loadOptions={this.loadCategory}
                openMenuOnClick={true}
                isClearable={false}
                label={"I'm looking for:"}
                theme={theme}
                customTheme={customTheme}
              />
              <AsyncDropdown
                defaultOptions={includeDefaultOptions}
                value={selectedOption['includeIngredients']}
                isMulti={true}
                isPara={isPara}
                placeholder="Type any ingredients"
                handleChange={handleChange('includeIngredients', mixpanel)}
                loadOptions={this.loadIngredients("Include")}
                openMenuOnClick={true}
                isClearable={false}
                label={"I want to include:"}
                theme={theme}
                customTheme={customTheme}
              />
              <AsyncDropdown
                defaultOptions={excludeDefaultOptions}
                value={selectedOption['excludeIngredients']}
                isMulti={true}
                isPara={isPara}
                placeholder="Type any ingredients"
                handleChange={handleChange('excludeIngredients', mixpanel)}
                loadOptions={this.loadIngredients("Exclude")}
                openMenuOnClick={true}
                isClearable={false}
                label={"I want to exclude:"}
                theme={theme}
                customTheme={customTheme}
              />
              {
                !isPara &&
                <div className="block">
                  <Button 
                    buttonText="Find" 
                    handleClick={(e) => handleSubmit(e,mixpanel)} 
                    disabled={isDisabled}
                    resultCount={resultCount}
                    isLoading={isLoading}
                    loader={loader}
                    flag={flag}
                    customTheme={customTheme}
                  />
                  
                </div>
              }
            </Fragment>
          )
        }
      </MixpanelConsumer>
        {/* <AsyncDropdown
          defaultOptions={categoryDefaultOptions}
          value={selectedOption['categories']}
          isMulti={true}
          isPara={isPara}
          placeholder="Select a Category"
          handleChange={handleChange('categories')}
          loadOptions={this.loadCategory}
          openMenuOnClick={true}
          isClearable={false}
          label={"I'm looking for:"}
          theme={theme}
        />
        <AsyncDropdown
          defaultOptions={includeDefaultOptions}
          value={selectedOption['includeIngredients']}
          isMulti={true}
          isPara={isPara}
          placeholder="Type any ingredients"
          handleChange={handleChange('includeIngredients')}
          loadOptions={this.loadIngredients("Include")}
          openMenuOnClick={true}
          isClearable={false}
          label={"I want to include:"}
          theme={theme}
        />
        <AsyncDropdown
          defaultOptions={excludeDefaultOptions}
          value={selectedOption['excludeIngredients']}
          isMulti={true}
          isPara={isPara}
          placeholder="Type any ingredients"
          handleChange={handleChange('excludeIngredients')}
          loadOptions={this.loadIngredients("Exclude")}
          openMenuOnClick={true}
          isClearable={false}
          label={"I want to exclude:"}
          theme={theme}
        /> */}
        {/* {
          !isPara &&
          <div className="block">
            <Button 
              buttonText="Find" 
              handleClick={handleSubmit} 
              disabled={isDisabled}
              resultCount={resultCount}
              isLoading={isLoading}
              loader={loader}
              flag={flag}
            />
            
          </div>
        } */}
      </div> 
    );
  }
}

Form.defaultProps = {
  selectedOption: {}
};

export default Form;