import React, { Component } from 'react';
import { Header, Loader } from 'components';

import { FaExclamationCircle } from 'react-icons/fa'
import './style/style.scss';

/**
 * @class ErrorPage
 * @extends {Component}
 */

class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    if(this.props.location.state) {
      this.setState({ isLoading: false})
    }
  }

  render() {
    let {
      isLoading
    } = this.state;

    let {
      errorMessage
    } = this.props.location.state;

    if (isLoading) {
      return <Loader />
    }

    return (
      <div className="error">
        <Header />
        <div className="error-container">
          <FaExclamationCircle className="error-icon" />
          <div className="error-message">
            <span>{errorMessage}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;