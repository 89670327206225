import React, { Component } from 'react';
import styled from 'styled-components';

class Checkbox extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked
    }
  }

  toggleCheck = (e) => {
    if(this.state.isChecked) {
      this.setState({ isChecked: false })
     } else {
       this.setState({ isChecked: true })
     }
    this.props.onChange(e);
  }

  render() {
    let {
      label,
      value,
      isChecked,
      count,
      custombackgroundcolor,
      customtextcolor,

    } = this.props;

    return (
      <li>
        <label>
          <StyledCheckbox type="checkbox" name={value} onClick={this.toggleCheck} checked={isChecked ? true : false} custombackgroundcolor={custombackgroundcolor}/>
            <StyledSpan customtextcolor={customtextcolor} isChecked={isChecked}>{label} {count && `(${count})`}</StyledSpan>
        </label>
      </li>  
    )
  }
};

const StyledCheckbox = styled.input`
  background-color: #fff;
  border: 1px solid ${(props) => props.custombackgroundcolor};
  &:after {
    content: '';
  }
  &:checked::after {
    color: #fff
  }
  &:checked {
    background-color: ${(props) => props.custombackgroundcolor};
    border: 1px solid ${(props) => props.custombackgroundcolor};
  }
  -webkit-appearance: none;
`

const StyledSpan = styled.span`
  color:  ${(props) => props.isChecked ? props.customtextcolor : "#000"};
`;

export default Checkbox;