
import FetchUtils from 'utils/FetchUtils';

const  getCategory = async( category="",clientName='' ) => {
  const URL = `${global.API_BASE_URL}/category/search/?clientname=${clientName}&term=${category}`;
  const response = await FetchUtils.getData(URL,'categorylist ==>');
  return response;
};

const  getIngredients = async(ingredients="",clientName='',tag) => {
  const URL = `${global.API_BASE_URL}/ingredient/search?clientname=${clientName}&term=${ingredients}&tag=${tag}`;
  const response = await FetchUtils.getData(URL,'ingredientlist ==>');
  return response;
};

const  getBrands = async(brand="",clientName='') => {
  const URL = `${global.API_BASE_URL}/brand/search?clientname=${clientName}&term=${brand}`;
  const response = await FetchUtils.getData(URL,'brandlist ==>');
  return response;
};

const  getFilteredList = async(selectedFilter = {},clientName='') => {
  let eachfilter, query='';
  for( eachfilter in selectedFilter ) {
    if(Array.isArray(selectedFilter[eachfilter]) && selectedFilter[eachfilter].length!==0){
      let queryArr = selectedFilter[eachfilter].map((ele) => (ele.value));
      let queryformat = queryArr.map(ele => (ele.replace('&','%26')))
      query=`${query}${eachfilter}=${queryformat.join(', ')}&`
    }
  }
  const URL = `${global.API_BASE_URL}/products/filter?clientname=${clientName}&${query}`;
  // const URL = 'http://staging.clearforme.com:8081/api/app/products/filter?clientname=credo&categories=aromatherapy&'
  const response = await FetchUtils.getData(URL,'productlist ==>');
  return response;
};


export {
  getIngredients,
  getCategory,
  getFilteredList,
  getBrands
};