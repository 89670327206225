import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FaSync, FaTimes } from "react-icons/fa";
import { AsyncDropdown, Accordion } from 'components';

import { getIngredients } from 'src/services/SearchServices';
import { getCategory } from 'src/services/SearchServices';

import TextUtils from 'utils/TextUtils';
import Checkbox from '../Checkbox';
import styled from 'styled-components';
import { FaArrowLeft } from  "react-icons/fa";
import { MixpanelConsumer } from 'react-mixpanel';


import './style/style.scss';

const includeDefaultOptions = true;

const excludeDefaultOptions = true;

class Filter extends Component {
  renderList = (listName) => {
    let sOption = this.props[listName].map((ele) => (JSON.stringify(ele)));
    let sSelectedOption = this.props.selectedOption[listName] && this.props.selectedOption[listName].map((ele) => (JSON.stringify(ele)));
    let Option = sOption.map((ele,index) => {
      let isChecked = sSelectedOption && sSelectedOption.includes(ele);
        let parseData = JSON.parse(ele);
          return (
            <>
            <MixpanelConsumer key={index}>
              {
                mixpanel => (
                  <Checkbox
                    sOption={ele}
                    sSelectedOption={sSelectedOption}
                    label={parseData.label} 
                    value={parseData.value} 
                    count={parseData.count}
                    isChecked={isChecked}
                    onChange={this.props.handleFilter(listName,parseData,mixpanel)}
                    custombackgroundcolor={ this.props.customTheme && this.props.customTheme.background ? this.props.customTheme.background.colorTheme.primary.filterSelectedEntitiesAndCheckBox.css.filterCheckbox.backgroundColor ? this.props.customTheme.background.colorTheme.primary.filterSelectedEntitiesAndCheckBox.css.filterCheckbox.backgroundColor : this.props.theme.primaryColor : this.props.theme.primaryColor }
                    customtextcolor={ this.props.customTheme && this.props.customTheme.background ? this.props.customTheme.background.colorTheme.primary.filterSelectedEntitiesAndCheckBox.css.filterCheckbox.textColor ? this.props.customTheme.background.colorTheme.primary.filterSelectedEntitiesAndCheckBox.css.filterCheckbox.textColor : this.props.theme.primaryColor : this.props.theme.primaryColor }
                  />
                )
              }
            </MixpanelConsumer>
            </>
          )
      })

    return Option;
  }

  // Render the chip component
  renderChips = (filterType,filterData) => {
    let eachType, List = [];
    for( eachType in filterData ) {
      if(Array.isArray(filterData[eachType]) && filterType === eachType) {
        List.push( filterData[eachType].map((item,index) => (
          <li key={index}>
            <Chips customcolor={ this.props.customTheme && this.props.customTheme.background ? this.props.customTheme.background.colorTheme.primary.filterSelectedEntitiesAndCheckBox.css.filterSelected.textColor ? this.props.customTheme.background.colorTheme.primary.filterSelectedEntitiesAndCheckBox.css.filterSelected.textColor : this.props.theme.primaryColor : this.props.theme.primaryColor} to="#FIXME" title={item.label}>{item.label}</Chips>
            <StyledTimes customcolor={ this.props.customTheme && this.props.customTheme.background ? this.props.customTheme.background.colorTheme.primary.filterSelectedEntitiesAndCheckBox.css.filterSelected.textColor ? this.props.customTheme.background.colorTheme.primary.filterSelectedEntitiesAndCheckBox.css.filterSelected.textColor : this.props.theme.primaryColor : this.props.theme.primaryColor} className="close-icon" onClick={this.props.handleRemoveFilter(eachType,item)} />
          </li>
        )))
      }
    } 
    
    return List;  
  }

  render() {
    let {
      accordionData,
      includeingredientsData,
      excludeingredientsData,
      selectedOption,
      brandsData,
      handleChange,
      handleClearFilter,
      theme,
      showFilter,
      handleFilterMenu,
      searchValue,
      customTheme,
    } = this.props;
    return(
      <div className={`aside-container ${showFilter && `show`}`}>
        <aside>
          <section className="filter">
              <StyledBackIcon onClick={handleFilterMenu(false)} />
            <div className="filter-head">
              <h3>Filter By</h3>
              <StartOver to="#FIXME" 
                onClick={handleClearFilter} 
                title="Start Over"
                customcolor={customTheme && this.props.customTheme.background ? (customTheme.background.colorTheme.primary.filterStartOver.css.textColor) ? customTheme.background.colorTheme.primary.filterStartOver.css.textColor : this.props.theme.primaryColor : this.props.theme.primaryColor }>
                Start Over <StyledFaSync />
              </StartOver>
            </div>
          </section>
          <h5>I am looking for:</h5>
          <Accordion>
            {
              accordionData ?
              accordionData.map((item,index) => { 
                return (
                <div key={index} label={item.title} >
                  <input
                    className="category"
                    type="text"
                    placeholder="Search"
                    onChange={searchValue(item.value)}
                  />  
                  <ul>
                    { this.props[item.value] && this.props[item.value].length!==0 ? 
                      this.renderList(item.value)     
                      : <li>No options available</li>
                    }
                  </ul>  
                </div>
              )}) : 
              null
            }
          </Accordion>
          {
            accordionData ?
            accordionData.map((item,index) => { 
              return (
              <div key={index} label={item.title} >
                <ul className="filter-list">
                  { 
                    selectedOption ? 
                    this.renderChips(item.value, selectedOption) : null 
                  }
                </ul>
              </div>  
            )}) : null
          }
          <h5>I want to:</h5>
          <Accordion>
            {
              includeingredientsData ?
              includeingredientsData.map((item,index) => { 
                return (
                <div key={index} label={item.title} >
                  <input
                    className="category"
                    type="text"
                    placeholder="Search"
                    onChange={searchValue(item.value)}
                  />  
                  <ul>
                    { this.props[item.value] && this.props[item.value].length!==0 ? 
                      this.renderList(item.value)     
                      : <li>No options available</li> 
                    }
                  </ul>  
                </div>
              )}) : 
              null
            }
          </Accordion>
          {
            includeingredientsData ?
            includeingredientsData.map((item,index) => { 
              return (
              <div key={index} label={item.title} >
                <ul className="filter-list">
                  { 
                    selectedOption ? 
                    this.renderChips(item.value, selectedOption) : null 
                  }
                </ul>
              </div>  
            )}) : null
          }
          <h5>I want to:</h5>
          <Accordion>
            {
              excludeingredientsData ?
              excludeingredientsData.map((item,index) => { 
                return (
                <div key={index} label={item.title} >
                  <input
                    className="category"
                    type="text"
                    placeholder="Search"
                    onChange={searchValue(item.value)}
                  />  
                  <ul>
                    { this.props[item.value] && this.props[item.value].length!==0 ? 
                      this.renderList(item.value)     
                      : <li>No options available</li> 
                    }
                  </ul>  
                </div>
              )}) : 
              null
            }
          </Accordion>
          {
            excludeingredientsData ?
            excludeingredientsData.map((item,index) => { 
              return (
              <div key={index} label={item.title} >
                <ul className="filter-list">
                  { 
                    selectedOption ? 
                    this.renderChips(item.value, selectedOption) : null 
                  }
                </ul>
              </div>  
            )}) : null
          }
          <h5>I am looking for:</h5>
          <Accordion>
            {
              brandsData ?
              brandsData.map((item,index) => { 
                return (
                <div key={index} label={item.title} >
                  <ul>
                    { this.props[item.value] && this.props[item.value].length!==0 ? 
                      this.renderList(item.value)     
                      : <li>No options available</li>
                    }
                  </ul>  
                </div>
              )}) : 
              null
            }
          </Accordion>
          {
            brandsData ?
            brandsData.map((item,index) => { 
              return (
              <div key={index} label={item.title} >
                <ul className="filter-list">
                  { 
                    selectedOption ? 
                    this.renderChips(item.value, selectedOption) : null 
                  }
                </ul>
              </div>  
            )}) : null
          }
        </aside>  
        <div className="power-label">
          <span>Powered by <a href="http://www.clear4me.com" target="_blank" rel="noopener noreferrer" title="Clear For Me" className="logo"> clear<span>For</span>Me</a></span>
        </div>
      </div>
    )
  }
}

const Chips = styled(Link)`
  padding: 3px 5px;
  border-radius: 5px;
  color: ${(props) => (props.customcolor)};
  font: 900 12px 'Roboto';
  transition: .5s;
`;

const StyledTimes = styled(FaTimes)`
  color: ${(props) => (props.customcolor)};
`;

const StyledBackIcon =  styled(FaArrowLeft)` 
  display: none;
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 101;
  font-size: 25px;
  color: ${(props) => props.theme.primaryColor};
  @media screen and (max-width: 995px) { display: block; }     
`

const StartOver = styled(Link)`
  display: inline-block;
  font-family: secondary-selected-font, "Roboto", sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.customcolor};
  text-transform: uppercase;
`

const StyledFaSync = styled(FaSync)`
  position: relative;
  top: 2px;
  left: 3px;
  font-size: 15px;
`

export default Filter;