import React from 'react';
import styled from 'styled-components';
// styles
import './style/style.scss';

const Loader = () => {
    return(
      <div className="loader">
        <div className="lds-ring">
          <StyledLoader></StyledLoader>
          <StyledLoader></StyledLoader>
          <StyledLoader></StyledLoader>
          <StyledLoader></StyledLoader>
        </div>
      </div>
    )
}

const StyledLoader = styled.div`
  border: ${(props) => `6px solid ${props.theme.primaryColor}`};
  border-color: ${(props) => props.theme.primaryColor} transparent transparent transparent;
`

export default Loader;