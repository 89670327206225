import React from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import { FaSearch } from 'react-icons/fa'
import './style/style.scss';
import styled from 'styled-components';

// const SearchIndicator = props => {
//   return (
//     components.DropdownIndicator && (
//       <components.DropdownIndicator {...props}>
//         <FaSearch />
//       </components.DropdownIndicator>
//     )
//   );
// };

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FaSearch />
      </components.DropdownIndicator>
    )
  );
};

const AsyncDropdown = (props) => {
  let {
    handleChange,
    loadOptions,
    isMulti,
    openMenuOnClick,
    value,
    isPara,
    placeholder,
    defaultOptions,
    isHidden,
    customTheme,
  } = props;

  const style = props.theme;
  const customStyle = customTheme ? customTheme.background ? customTheme.background.colorTheme ? customTheme.background.colorTheme : null : null: null;
  const colourStyles = (!isPara) ? {
    control: (styles,{ isFocused }) => ({ ...styles, backgroundColor: 'white' , 
    border: '1px solid #ccc',  
    borderColor: isFocused ? customStyle && customStyle.primary.dropdownFocus.css.textColor ? customStyle.primary.dropdownFocus.css.textColor : style.primaryColor : '#ccc'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if(window.innerWidth <= 1024) {
        isFocused = false;
      }

      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected ?  customStyle && customStyle.primary.dropdownFocus.css.backgroundColor ? customStyle.primary.dropdownFocus.css.backgroundColor : style.primaryColor : isFocused ? customStyle && customStyle.primary.dropdownFocus.css.backgroundColor ? customStyle.primary.dropdownFocus.css.backgroundColor : style.primaryColor : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? customStyle && customStyle.primary.dropdownFocus.css.textColor ? customStyle.primary.dropdownFocus.css.textColor : style.primaryColor
            : isFocused ? customStyle && customStyle.primary.dropdownFocus.css.textColor ? customStyle.primary.dropdownFocus.css.textColor : '#fff'
            : data.color,
        ':active': {
          backgroundColor: customStyle && customStyle.primary.dropdownFocus.css.backgroundColor ? customStyle.primary.dropdownFocus.css.backgroundColor : style.primaryColor,
        }    
      };
    },
    dropdownIndicator: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled ? '#ccc' : isFocused ? customStyle && customStyle.primary.dropdownFocus.css.textColor ? customStyle.primary.dropdownFocus.css.textColor : style.primaryColor : '#ccc',
      }  
    },
    multiValue: (styles) => {
      return {
        ...styles,
        color: '#000',
        backgroundColor: customStyle && customStyle.primary.searchResultsInFocusBox.css.backgroundColor ? customStyle.primary.searchResultsInFocusBox.css.backgroundColor : '#f2f2f2',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: customStyle && customStyle.primary.searchResultsInFocusBox.css.textColor ? customStyle.primary.searchResultsInFocusBox.css.textColor : '#6d6e71'
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: customStyle && customStyle.primary.searchResultsInFocusBox.css.textColor ? customStyle.primary.searchResultsInFocusBox.css.textColor : '#6d6e71',
      ':hover': {
        backgroundColor: style.primaryFaded,
        color: 'white',
      },
    }),
  } : {
    control: (styles,{ isFocused }) => ({ ...styles, backgroundColor: 'white' , 
    border: 'none',    
    borderBottom: `2px dashed ${style.primaryText}`,
    borderColor: isFocused ? style.primaryColor : '#ccc',
    ':hover': {
      borderBottom: `2px dashed ${style.primaryColor}`,
    },
    ':focus': {      
      borderBottom: `2px dashed ${style.primaryColor}`,
    },
     }),
    dropdownIndicator: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled ? '#ccc' : isFocused ? customStyle && customStyle.primary.dropdownFocus.css.textColor ? customStyle.primary.dropdownFocus.css.textColor : style.primaryColor : '#ccc',
      }  
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected ?  customStyle && customStyle.primary.dropdownFocus.css.backgroundColor ? customStyle.primary.dropdownFocus.css.backgroundColor : style.primaryColor : isFocused ? customStyle && customStyle.primary.dropdownFocus.css.backgroundColor ? customStyle.primary.dropdownFocus.css.backgroundColor : style.primaryColor : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? '#ccc' > 2 ? 'white' : 'black'
            : data.color,
        ':active': {
          backgroundColor: customStyle && customStyle.primary.dropdownFocus.css.backgroundColor ? customStyle.primary.dropdownFocus.css.backgroundColor : style.primaryColor,
        }    
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: customStyle && customStyle.primary.searchResultsInFocusBox.css.backgroundColor ? customStyle.primary.searchResultsInFocusBox.css.backgroundColor : '#f2f2f2',
      };
    },
    multiValueRemove: (styles) => ({
      ...styles,
      color: '#fff',
      ':hover': {
        backgroundColor: style.primaryFaded,
        color: 'white',
      },
    }),
  };

  return(
    <div className={`${isPara ? `paragraph` : 'input-container'} ${isHidden && !isPara && 'hide'}`}>
      {
        props.label && 
        <div className="input-label">
          <span>{props.label}</span>
        </div>
      }
      <div className="input-box">
        <AsyncSelect
          defaultOptions={defaultOptions}
          value={value}
          placeholder={placeholder}
          components={{DropdownIndicator}}
          isMulti={isMulti}
          loadOptions={loadOptions}
          onChange={handleChange}
          openMenuOnClick={openMenuOnClick}
          className="dropdown"
          classNamePrefix="dropdown"
          styles={colourStyles}
        />
      </div>
    </div>
  )
}

const styledDropdown = styled(AsyncDropdown)`
`;

export default styledDropdown;

