import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Loader, Form } from "components";

import ListItem from "./components/ListItem";
import Filter from "./components/Filter";
import InfoModal from 'components/infoModal';

import { SelectFilterAction } from "actions/SelectFilterAction";
import { trackValues } from 'pages/search'; 
import { getFilteredList, getCategory, getIngredients } from "src/services/SearchServices";
import Select from "react-select";
import "./style/style.scss";
import InfiniteScroll from "react-infinite-scroller";
import TextUtils from "utils/TextUtils";
import defaultBackgroundImage from "assets/images/result-header-image.jpg";

import { FaInfoCircle, FaArrowUp } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

const accordionData = [
  {
    title: "Category",
    value: "categories"
  }
];

const brandsData = [
  {
    title: "Brand",
    value: "brand"
  }
];

const includeingredientsData = [
  {
    title: "Include Ingredient",
    value: "includeIngredients"
  }
];

const excludeingredientsData = [
  {
    title: "Exclude Ingredient",
    value: "excludeIngredients"
  }
];

const sortOptions = [
  { value: "alphaAsc", label: "Alphabetical (A-Z)" },
  { value: "alphaDesc", label: "Alphabetical (Z-A)" },
  { value: "numAsc", label: "Price (Low to High)" },
  { value: "numDesc", label: "Price (High to Low)" }
];

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = dispatch => ({
  SelectFilterAction: selectedFilter =>
    dispatch(SelectFilterAction(selectedFilter))
});

/*
 * mapStateToProps
 */
const mapStateToProps = state => ({
  ...state
});

/**
 * @class ListingPage
 * @extends {Component}
 */

class ListingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: null,
      selectedOption: props.selectedFilter,
      isFiltered: true,
      hasMoreScroll: false,
      paginatedList: [],
      page: 1,
      searchStep: 1,
      isResetFilter: false,
      showFilter: false,
      openModal: false,
      handlesClicked: 0,
    };
  }

  componentDidMount() {
    if(!(this.isEmpty(this.props.selectedFilter))) {
      this.getListing();
    } else {
      this.setState({
        isResetFilter: false,
        isLoading: false,
        isFiltered: false,
        isNoProducts: false,
      })
    }  
    this.getCategoryOptions();
    this.defaultIngredients('Include', 'includeIngredients');
    this.defaultIngredients('Exclude', 'excludeIngredients');
    this.setCurrentStep();
    window.addEventListener('scroll', this.scrollFunction);
    const customTheme = this.props.history.location.state;
    if (customTheme && customTheme.background) {
      document.querySelector('body').style.color = customTheme.background.colorTheme.tertiary.css.textColor ? customTheme.background.colorTheme.tertiary.css.textColor : 'inherit';
    }
    if(customTheme && customTheme.siteFont && customTheme.siteFont.secondaryFont.fontFamily) {
      let fontData =  customTheme.siteFont.secondaryFont.fontWeight
        ? customTheme.siteFont.secondaryFont.fontFamily.files[customTheme.siteFont.secondaryFont.fontWeight]
        : customTheme.siteFont.secondaryFont.fontFamily.files[
            Object.keys(customTheme.siteFont.secondaryFont.fontFamily.files)[0]
          ]
      if(fontData) {
        this.loadFonts(fontData, 'secondary');
      }
    }

    if(customTheme && customTheme.siteFont && customTheme.siteFont.primaryFont.fontFamily) {
      let fontData =  customTheme.siteFont.primaryFont.fontWeight
        ? customTheme.siteFont.primaryFont.fontFamily.files[customTheme.siteFont.primaryFont.fontWeight]
        : customTheme.siteFont.primaryFont.fontFamily.files[
            Object.keys(customTheme.siteFont.primaryFont.fontFamily.files)[0]
          ]
      if(fontData) {
        this.loadFonts(fontData, 'primary');
      }
    }


    this.setState({ customThemeSet: customTheme });
  }
  
  loadFonts = async (url, fontLevel) => {
    let font = '';
    if(fontLevel === 'secondary') {
      font = new FontFace('secondary-selected-font', `url(${url})`);
    } else if (fontLevel === 'primary') {
      font = new FontFace('primary-selected-font', `url(${url})`);
    }
    await font.load();
    document.fonts.add(font);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollFunction);
  }

  defaultIngredients = (tag, inputValue) => {
    this.getIngredientsOptions('',inputValue,tag);
  } 

  scrollFunction = () => {
    if(document.querySelector('.scroll-to-top')) {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.querySelector('.scroll-to-top').style.display = "block";
      } else {
        document.querySelector('.scroll-to-top').style.display = "none";
      }
    }
  }

     /* 
    * Search category
  */
 searchValue = (inputValue) => async(e) => {
   if(inputValue==="categories") {
      await this.getCategoryOptions(e.target.value);
    } else if(inputValue === "includeIngredients") {
      await this.getIngredientsOptions(e.target.value,inputValue, 'Include');    
    } else if(inputValue === "excludeIngredients") {
      await this.getIngredientsOptions(e.target.value,inputValue, 'Exclude');    
    }
  }

  setCurrentStep = () => {
    let eachfilter;
    let { selectedFilter } = this.props;
    for( eachfilter in selectedFilter ) {
      if(eachfilter==='categories'){
        this.setState({
          searchStep: 1
        })  
      } else if (eachfilter==='includeIngredients') {
        this.setState({
          searchStep: 2
        })  
      } else if (eachfilter==='excludeIngredients') {
        this.setState({
          searchStep: 3
        })  
      }
    }  
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  redirectToError = (error = "Oops! Something went wrong.") => {
    this.props.history.push({
      pathname: `/error`,
      state: {
        errorMessage: error
      }
    });
  };

  getListing = async (isBrandUpdated) => {
    const response = await getFilteredList(this.state.selectedOption, this.props.clientName.clientName);
    if (response.status !== 404 && response.data.error) {
      this.setState({
        isLoading: false,
        isFiltered: false
      });
      this.redirectToError(response.data.error);
    } else {
     if(response.data && (response.data.length === 0) && !this.state.isResetFilter) {
        this.setState(
          {
            isNoProducts: true,
            isFiltered: false,
            isLoading: false,
            productList: response.data.products,
            hasMoreScroll: true,
          },
          () => {
            window.scrollTo(0, 0);
            this.loadItems();
            if(!isBrandUpdated) {
              this.getBrandOptions(this.state.productList);
            }
          }
        );
     } else {
      this.setState(
        {
          isNoProducts: false,
          isFiltered: false,
          isLoading: false,
          productList: response.data && response.data.products,
          hasMoreScroll: true,
          isResetFilter: false
        },
        () => {
          window.scrollTo(0, 0);
          this.loadItems();
          if(!isBrandUpdated) {
              this.getBrandOptions(this.state.productList);
          }
        }
      );
      this.handleSort({value: 'alphaAsc', label: 'Alphabetical (A-Z)'});
     }
    }
  };

  /*
   * Get Category options
   */
  getCategoryOptions = async (term) => {
    const response = await getCategory(term, this.props.clientName.clientName);

    let options = response.data.map(ele => {
      let eachOption = {};
      eachOption["label"] = TextUtils.capitalize(ele.category);
      eachOption["value"] = ele.category;
      return eachOption;
    });
    // remove empty options
    let trimOptions = options.filter(ele => !!ele.value);
    this.setState({
      categories: trimOptions
    });
  };

  /* 
  * Get Ingredients options
  */
 getIngredientsOptions = async (term,inputValue,tag) => {
   const response = await getIngredients(term, this.props.clientName.clientName, tag);
    // format the option in react-select format({value: string , label: string})
    let options = response.data.map((ele) => {
      let eachOption = {};
      if (ele.ingredientName) {
        eachOption['label'] = TextUtils.capitalize(ele.ingredientName);
        eachOption['value'] = ele.ingredientName;
        return eachOption;
      } else if (ele.synonym) {
        eachOption['label'] = TextUtils.capitalize(ele.synonym);
        eachOption['value'] = ele.synonym;
        return eachOption;
      }
    });
    if(inputValue == "includeIngredients") {
      this.setState({ includeIngredients: options });
    } else if (inputValue == "excludeIngredients") {
      this.setState({ excludeIngredients: options });
    } else {
      this.setState({ 
        excludeIngredients: options,
        includeIngredients: options 
      });
    }
  }

  /*
   * Get Brand options
   */
  getBrandOptions = async (productList = []) => {
    let brandOptions = productList.map(ele => ele.brand); 
    let uniqueOptions = new Set(brandOptions);
    let uniqueOptionsArr = [...uniqueOptions];
    let options = uniqueOptionsArr.map(ele => {
      let value = 0;
      for (let i = 0; i < brandOptions.length; i++) {
        if (brandOptions[i] === ele) {
          value = value + 1;
        }
      }
      let eachOption = {};
      eachOption["label"] = TextUtils.capitalize(ele);
      eachOption["value"] = ele;
      eachOption["count"] = value;
      return eachOption;
    });

    if (options) {
      let sortedBrandList = options;
      sortedBrandList.sort(function(a, b) {
        var nameA = a.value.toUpperCase(); // ignore upper and lowercase
        var nameB = b.value.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({
        brand: sortedBrandList
      })
    }
  };

  nextStep = (nextStep) => {
    let { searchStep } = this.state;
    if ( nextStep <= 3 ) {
      this.setState({
        searchStep: searchStep + 1
      });
    }
  };

  /*
   * Handle Change of input
   */
  handleChange = value => (selectedOption, { action }) => {
    if (value === "categories") {
      let selectedValue = this.state.selectedOption;
      selectedValue[value] = selectedOption;
      if (
        action === "select-option" ||
        action === "remove-value" ||
        action === "clear"
      ) {
        this.setState(
          {
            paginatedList: [],
            isFiltered: true,
            selectedOption: selectedValue
          },
          () => {
            this.getListing();
          }
        );
        if (action === "clear" || action === "remove-value") {
          this.setState({ isResetFilter: true })
        }
      }  
    } else if (value === "includeIngredients") {  
      let selectedValue = this.state.selectedOption;
      selectedValue[value] = selectedOption;
      if (
        action === "select-option" ||
        action === "remove-value" ||
        action === "clear"
      ) {
        this.setState(
          {
            paginatedList: [],            
            isFiltered: true,
            selectedOption: selectedValue
          },
          () => {
            this.getListing();
          }
        );
        if (action === "clear" || action === "remove-value") {
          this.setState({ isResetFilter: true })
        }
      }
    } else if (value === "excludeIngredients") {
      let selectedValue = this.state.selectedOption;
      selectedValue[value] = selectedOption;
      if (
        action === "select-option" ||
        action === "remove-value" ||
        action === "clear"
      ) {
        this.setState(
          {
            paginatedList: [],
            isFiltered: true,
            selectedOption: selectedValue
          },
          () => {
            this.getListing();
          }
        );
        if (action === "clear" || action === "remove-value") {
          this.setState({ isResetFilter: true })
        }
      }
    } else {
      console.log("Invalid Field");
    }
  };

  loadItems = (innerPage = 1, isSorted) => {
    let { productList, paginatedList, page } = this.state;
    if (productList && !(paginatedList.length === productList.length) && !isSorted) {
      let paginatedList = productList && productList.slice(0, page * 9);
      let hasMoreScroll = paginatedList.length <= productList.length;
      if(page === 1) {
        this.setState({
          page: page + 1,
          paginatedList,
          hasMoreScroll
        });
      } else {
        setTimeout(() => {
          this.setState({
            page: page + 1,
            paginatedList,
            hasMoreScroll
          });
        },1000);
      }
    } else if (isSorted) {
      let paginatedList = productList && productList.slice(0, page * 9);
      let hasMoreScroll = paginatedList.length <= productList.length;
      this.setState({
        page: page,
        paginatedList,
        hasMoreScroll
      });
    } else {
      this.setState({
        page: 1,
        hasMoreScroll: false,
      });
    }
  };

  /*
   * Remove selected filter chips
   */
  handleRemoveFilter = (value, ele) => e => {
    e.preventDefault();
    let selectedValues = this.state.selectedOption;
    selectedValues[value] =
      selectedValues[value] &&
      selectedValues[value].filter(option => {
        if (option.value !== ele.value) {
          return true;
        } else {
          return false;
        }
      });
      let filteredSearchToTrack = trackValues(selectedValues);
      // mixpanel.track('Filtered Search', { value: filteredSearchToTrack });
    this.setState(
      {
        paginatedList: [],
        selectedOption: selectedValues,
        isFiltered: true
      },
      () => {
        this.getListing();
      }
    );
  };

  // Handle clear all
  handleClearFilter = e => {
    e.preventDefault();
    let clearValue = {
      categories: [],
      brand: [],
      includeIngredients: [],
      excludeIngredients: []
    };
    this.props.SelectFilterAction(clearValue);
    this.setState(
      {
        isResetFilter: true,
        paginatedList: [],
        selectedOption: clearValue
      },
      () => {
        this.getListing();
      }
    );
  };

  // Handle click of any filter
  handleFilter = (value, ele, mixpanel) => e => {
    let selectedValues = this.state.selectedOption;
    if (e.target.checked) {
      if (selectedValues[value])
        selectedValues[value] = [...selectedValues[value], ele];
      else selectedValues[value] = [ele];
      let filteredSearchToTrack = trackValues(selectedValues);
      mixpanel.track('Filtered Search', { value: filteredSearchToTrack });
    } else {
      selectedValues[value] =
        selectedValues[value] &&
        selectedValues[value].filter(option => {
          if (option.value !== ele.value) {
            return true;
          } else {
            return false;
          }
        });
    }
    this.setState(
      {
        paginatedList: [],
        selectedOption: selectedValues,
        isFiltered: true
      },
      () => {
        if(value === "brand") {
          this.getListing(true);
        } else {
          this.getListing();
        }
      }
    );
  };

  scrollTop = () => {
    window.scrollTo(0, 0);
  }

  handleFilterMenu = (show) => (e) => {
    if(show) {
      this.setState({ showFilter: true })
    } else {
      this.setState({ showFilter: false });
    }
  }

  // handle Sort
  handleSort = ({ value, label }) => {
    if (value === "alphaAsc" && this.state.productList) {
      let sortedproductList = this.state.productList;
      sortedproductList.sort(function(a, b) {
        var nameA = a.productName.toUpperCase(); // ignore upper and lowercase
        var nameB = b.productName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({
        productList: sortedproductList
      },() => {
      this.loadItems(1,true);
      });
    } else if (value === "alphaDesc" && this.state.productList) {
      let sortedproductList = this.state.productList;
      sortedproductList.sort(function(a, b) {
        var nameA = a.productName.toUpperCase(); // ignore upper and lowercase
        var nameB = b.productName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      sortedproductList.reverse();
      this.setState({
        productList: sortedproductList
      },() => {
        this.loadItems(1,true);
        });
    } else if (value === "numAsc" && this.state.productList) {
      let sortedproductList = this.state.productList;
      sortedproductList.sort(function(a, b) {
        return a.price - b.price;
      });
      this.setState({
        productList: sortedproductList
      },() => {
        this.loadItems(1,true);
        });
    } else if (value === "numDesc" && this.state.productList) {
      let sortedproductList = this.state.productList;
      sortedproductList.sort(function(a, b) {
        return a.price - b.price;
      });
      sortedproductList.reverse();
      this.setState({
        productList: sortedproductList
      },() => {
        this.loadItems(1,true);
        });
    }
  };

  openModal = () => {
    this.setState(
      { openModal: true },
      () => {
        this.addBodyOverflow();
      }
      );
  }
  
  closeModal = () => {
    this.setState(
      { openModal: false },
      () => {
        this.removeBodyOverflow();
      }
    );
  }

  addBodyOverflow = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    body.style.overflow = "hidden";
    if(/iP(hone|od|ad)/.test(navigator.platform)) {
      body.classList.add('iosBody');
      html.classList.add('iosBody');
    }
  }

  removeBodyOverflow = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');

    body.style.overflow = "auto";
    if(/iP(hone|od|ad)/.test(navigator.platform)) {
      body.classList.remove('iosBody');
      html.classList.remove('iosBody');
    }
  }

  trackHandleClick = (mixpanel, brand, product, handle, e) => {
    let tempHandlesClicked = this.state.handlesClicked;
    tempHandlesClicked = tempHandlesClicked + 1;
    this.setState({ handlesClicked: tempHandlesClicked });
    const productHandle = `${brand}: ${product}`;
    if(mixpanel) {
      mixpanel.track('Total Handles clicked', { value: tempHandlesClicked });
      mixpanel.track(`Handle Clicked`, { value: productHandle.toUpperCase() });
    }
    return true;
  }

  render() {
    let {
      isLoading,
      productList,
      selectedOption,
      isFiltered,
      brand,
      categories,
      includeIngredients,
      excludeIngredients,
      paginatedList,
      hasMoreScroll,
      searchStep,
      isNoProducts,
      isResetFilter,
      showFilter,
      openModal,
      customThemeSet
    } = this.state;

    let { theme, clientName } = this.props;
    const style = theme;

    const colourStyles = {
      control: (styles,{ isFocused }) => ({ ...styles, backgroundColor: 'white' , 
      border: '0px solid #ccc',  
    }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected ?  style.primaryColor : isFocused ? style.primaryColor : null,
          color: isDisabled
            ? '#ccc'
            : isSelected
              ? '#ccc' > 2 ? 'white' : 'black'
              : data.color,
          ':active': {
            backgroundColor: style.primaryColor,
          }    
        };
      },
    }

    if (isLoading) {
      return <Loader />;
    }
    const customTheme = customThemeSet;
    return (
      <Fragment>
        <div className={`listing ${showFilter && `noScroll`}`}>
          <ResultsBackground
            background={
              customTheme.background && customTheme.background.results && customTheme.background.results.css? customTheme.background.results.css.backgroundImage ? 
              {
                image: customTheme.background.results.css.backgroundImage.secure_url
              } 
              : customTheme.background.results.css.backgroundColor ? {
                color: customTheme.background.results.css.backgroundColor
              }
              : null
              : null
            } 
            className="banner-content">
            {/* <h2><span>Your</span> Product Finder</h2> */}
            <StyledTitle
                  size={
                    customTheme.resultsTitle && customTheme.resultsTitle.css ? 
                    customTheme.resultsTitle.css.fontSize 
                    : null 
                  }
                ><span>{customTheme.resultsTitle && customTheme.resultsTitle.html ? customTheme.resultsTitle.html.split(' ')[0] : 'Your'}</span> {customTheme.resultsTitle && customTheme.resultsTitle.html ? customTheme.resultsTitle.html.split(' ').splice(1, customTheme.resultsTitle.html.length).join(' '): 'Product Finder'}</StyledTitle>
            <StyledModalBlock>
              <InfoModal customTheme={customTheme ? customTheme : null} />
            </StyledModalBlock>
          </ResultsBackground>  
          <div className="wrapper">
            <div className="content">
              {/* Filter */}
              {selectedOption && (
                <Filter
                  brand={brand}
                  categories={categories}
                  includeIngredients={includeIngredients}
                  excludeIngredients={excludeIngredients}
                  selectedOption={selectedOption}
                  accordionData={accordionData}
                  brandsData={brandsData}
                  includeingredientsData={includeingredientsData}
                  excludeingredientsData={excludeingredientsData}
                  handleFilter={this.handleFilter}
                  handleRemoveFilter={this.handleRemoveFilter}
                  handleClearFilter={this.handleClearFilter}
                  handleChange={this.handleChange}
                  handleFilterMenu={this.handleFilterMenu}
                  theme={theme}
                  showFilter={showFilter}
                  searchValue={this.searchValue}
                  customTheme={customTheme}
                />
              )}

              <div className="main-content">
                {/* <div className="top-filter">
                  <Form
                    isPara={true}
                    clearValue={this.clearValue}
                    selectedOption={selectedOption}
                    handleChange={this.handleChange}
                    searchStep={searchStep}
                    theme={theme}
                  />
                </div> */}
                <div className="list-wrapper">
                  <div className="filter" onClick={this.handleFilterMenu(true)}>
                    <span>Filter</span>
                  </div>
                  <div className="sort-container">
                    <span className="sort-by-label">sort by:</span>
                    <Select
                      defaultValue={{ value: "alphaAsc", label: "Alphabetical (A-Z)" }}
                      onChange={this.handleSort}
                      options={sortOptions}
                      className="dropdown"
                      classNamePrefix="dropdown"
                      styles={colourStyles}
                    />
                  </div>
                  <div className="count-container">
                    <span>
                      {productList && paginatedList && !isFiltered &&
                        productList.length !== 0 && paginatedList.length !== 0 && 
                        `Showing ${paginatedList.length} of ${productList.length} results`}
                    </span>
                    {/* <Info onClick={this.openModal}><StyledIcon/> Important: Please read this note if you are allergic/sensitive</Info>
                    <StyleModal className="modal_container" openModal={openModal}>
                      <StyledBackdrop openModal={openModal} onClick={this.closeModal}></StyledBackdrop>
                      <div className="modal">
                          <span>
                          Our filter is able to narrow in on what you’re looking for, but it cannot (yet) get down to the <strong>source</strong> of many ingredients. For example, if you exclude “coconut oil,” then products with the oil itself will be filtered out. But, other ingredients like caprylhydroxamic acid, a preservative that may be derived from coconuts, would not be filtered out. If you are sensitive/allergic to something, we encourage you to read ingredient lists carefully, contact brands directly for source info, and/or consult with a doctor. We’re continually iterating this search tool, so functionality will get better! But source-level data is tricky stuff. If you have any feedback or questions, email us at <Mail href="mailto:arealperson@credobeauty.com">arealperson@credobeauty.com</Mail> or Chat with us below!</span>
                          <span className="close" onClick={this.closeModal}>&times;</span>
                      </div>
                    </StyleModal> */}
                  </div>
                  {/* Main listing data */}
                  <InfiniteScroll
                    element="ul"
                    pageStart={0}
                    loadMore={this.loadItems}
                    hasMore={hasMoreScroll}
                    initialLoad={false}
                    loader={
                      <div className="loader-container">
                        <Loader />
                      </div>
                    }
                    className="list-container"
                  >
                    { isFiltered ? 
                    (<div className="loader-container"><Loader /></div>)
                    : (paginatedList && paginatedList.length !== 0 )? 
                    (
                      paginatedList.map((item, index) => (
                        <ListItem 
                          customTheme={customTheme} 
                          handleClickTrack={this.trackHandleClick} 
                          rootUrl={clientName.rootProductUrl} 
                          key={index} 
                          {...item} 
                        />
                      ))
                    )  :
                    (
                      isNoProducts && !isResetFilter &&
                      <li className="error">
                        {/* <h3>We’re sorry! </h3>
                        <h3>We couldn’t find what you are looking for.</h3>
                        <h3>
                          Talk to our specialists to help you find the right
                          products for you.
                        </h3> */}
                        <h3>Start over to find the best products for you.</h3>
                      </li>
                    )
                    }
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackToTop
        //  ref={(ref) => (this.scroll = ref )} 
        className='scroll-to-top'
         onClick={this.scrollTop}
         custombackgroundcolor={
          customTheme && customTheme.background ? customTheme.background.colorTheme.primary.backToTopArrowIcon.css.backgroundColor ? customTheme.background.colorTheme.primary.backToTopArrowIcon.css.backgroundColor : this.props.theme.primaryColor : this.props.theme.primaryColor
         }
        >
          <StyledTopIcon color={
            customTheme && customTheme.background ? customTheme.background.colorTheme.primary.backToTopArrowIcon.css.textColor ? customTheme.background.colorTheme.primary.backToTopArrowIcon.css.textColor : '#fff' : '#fff'
          } />
        </BackToTop>
      </Fragment>
    );
  }
}

const Info = styled.div`
  font-size: 13px;
  cursor: pointer;
  line-height: 1;
  font-family: 'Roboto',sans-serif;
  letter-spacing: .5px;
  color: #000;
`
const StyledTitle = styled.h2`
  font-family: primary-selected-font, Crimson Text, sans-serif;
  font-size: ${props => props.size ? props.size : '80px'}
  text-transform: captalize;
  span {
    font-family: inherit;
  }
`

const Mail = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.primaryColor}
  cursor: pointer;
`

const StyledIcon = styled(FaInfoCircle)`
  color: ${(props) => props.theme.primaryColor};
`

const StyledTopIcon = styled(FaArrowUp)`
  font-size: 25px;
  color: #fff;
`

const BackToTop = styled.div`
  padding: 10px;
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  background-color: ${(props) => props.custombackgroundcolor};
`

const StyleModal = styled.div`
  display: ${(props) => props.openModal ? "block" : "none"}
`

const StyledBackdrop = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => props.openModal ? "block" : "none"}
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.5);
`
const StyledModalBlock = styled.div`
  padding: 15px 0;
  position: relative;
  z-index: 5;
`

const ResultsBackground = styled.div`
  background: ${
    props => props.background ? props.background.image ? 
    `url(${props.background.image}) no-repeat` 
    : props.background.color ? props.background.color 
    : `url(${defaultBackgroundImage}) no-repeat` 
    : `url(${defaultBackgroundImage}) no-repeat`
  };
  background-size: 100% 100%;
`


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingPage);
