
// const API_BASE_URL = 'http://192.168.1.174:8081/api/app';
// const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1YzI5YjQ1NmY2NzY3ZTQ3OTlhZmY3NjciLCJjbGllbnRuYW1lIjoiY2xlYXJmb3JtZSIsInVzZXJuYW1lIjoic2FicmluYSIsInBhc3N3b3JkIjoiJDJhJDEwJGROOHlJTWRlbWhqcG1sVUcuMjcwQXVwU2dKMHd4QnJrMmpQZjdBWEd0amVrWUd1bG9kbkFXIiwiaXNBZG1pbiI6dHJ1ZSwiaWF0IjoxNTUyMjg4MTc0fQ.enrOL4Wt_ZMpkxbgmWt95dvXlf4i7MlnP0eHttfYnHk';
const API_BASE_URL = process.env.API_BASE_URL;
const TOKEN = process.env.TOKEN;
const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN;
const CMS_BASE_URL = process.env.CMS_BASE_URL;

global.API_BASE_URL = API_BASE_URL;
global.TOKEN = TOKEN;
global.MIXPANEL_TOKEN = MIXPANEL_TOKEN;
global.CMS_BASE_URL = CMS_BASE_URL;